import user from './user'
import company from './company'
import employees from './employees'
import documents from './documents'
import states from './states'
import salaryTypes from './salaryTypes'
import salary13Types from './salary13Types'
import qualifications from './qualifications'
import companyActivities from './companyActivities'
import salary from './salary'
import messages from './messages'

import { combineReducers } from 'recost'

export default combineReducers([
  salary,
  user,
  company,
  employees,
  documents,
  states,
  salaryTypes,
  salary13Types,
  qualifications,
  companyActivities,
  messages,
])
