import React, { memo } from 'react'
import classNames from 'classnames'
import { withRouter, Link } from 'react-router-dom'

import style from './style.module.scss'

function isActive (current, target) {
  if (target.includes('/activities/current')) {
    return current.includes('/activities/current') || 
      current.startsWith('/declaration') ||
      current.startsWith('/affiliation')
  } else if (target === '/') {
    return current === target
  } else {
    return current.startsWith(target)
  }
}

function HeaderLink({ to, name, children, location = {} }) {
  const aClasses = classNames({
    [style.active]: isActive(location.pathname, to),
  })
  return (
    <Link to={to} className={aClasses}>
      {children || name}
    </Link>
  )
}

export default withRouter(
  memo(HeaderLink)
)
