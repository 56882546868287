export const apiAddress = process.env.NODE_ENV !== 'development'
  ? '/api'
  : '/api'

export const paginationLimit = 50

export const dateFormat = 'DD.MM.YY'
export const inputDateFormat = 'DD.MM.YYYY'

export const minPasswordLength = 8
