import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import initContext, { Provider } from 'recost'
import logger from 'recost-logger'

import * as serviceWorker from './serviceWorker'
import 'config/locales'

import './theme/index.scss'
import Root from './views/Root'
import reducer from './reducers'
import { auth } from 'services'

import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')

// define the initial state for the application
const initialState = {
  user: auth.getAuthenticatedUser(),
  company         : {},
  proposal        : {},
  employees       : [],
  salaries        : [],
  documents       : [],
  employeesFilters: {},
  states          : [],
}

initContext(initialState, reducer, [logger])

ReactDOM.render(
  <Provider>
    <BrowserRouter>
      <Root/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
