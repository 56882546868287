export default function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_COMPANY_PENDING':
      return {
        ...state,
        error          : false,
        loadingCompany : true,
      }
    case 'FETCH_COMPANY_FULFILLED':
      const company = action.payload
      company.professions = company.professions.map((profession) => ({
        ...profession,
        value: true,
      }))
      return {
        ...state,
        company,
        error: false,
        loadingCompany: false,
      }
    case 'FETCH_COMPANY_REJECTED':
      return {
        ...state,
        error          : true,
        loadingCompany : false,
      }
    
    case 'FETCH_MESSAGES_FULFILLED':
      return {
        ...state,
        msgPreviouslySent : action.payload && action.payload.length > 0,
      }

    case 'CHANGE_COMPANY_EMAIL':
      return {
        ...state,
        company : {
          ...state.company,
          email : action.payload,
        },
      }
    case 'POST_MESSAGE_PENDING':
      return {
        ...state,
        postingMsg : true,
      }
    case 'POST_MESSAGE_FULFILLED':
      return {
        ...state,
        postingMsg : false,
        msgPosted  : true,
      }
    case 'POST_MESSAGE_REJECTED':
      return {
        ...state,
        msgPosted    : false,
        postingMsg   : false,
        msgError     : true,
      }
    default:
      return state
  }
}
