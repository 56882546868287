import {dispatch} from 'recost'

class Company {
  constructor (api) {
    this.api = api
  }

  getOrigins (config) {
    return this.api.get('/company/origins', config).then((origins) => {
      return origins.map((origin) => ({
        label: origin.display_name,
        value: origin.id,
      }))
    })
  } 

  getCompany (config) {
    dispatch({
      type: 'FETCH_COMPANY_PENDING',
    })
    return this.api.get('/company', config).then(payload => {
      dispatch({type: 'FETCH_COMPANY_FULFILLED', payload})
      return payload
    }).catch(err => {
      dispatch({ 
        type    : 'FETCH_COMPANY_REJECTED', 
        payload : err,
      })
      return err
    })
  }

  updateCompany (data, config) {
    return this.api.put('/company', data, config).then((payload) => {
      dispatch({ type: 'FETCH_COMPANY_FULFILLED', payload })
      return payload
    }).catch(err => {
      dispatch({ type: 'FETCH_COMPANY_REJECTED', payload : err })
      return err
    })
  }

  changeEmail(email, config) {
    const data = {
      email,
    }
    const request = this.api.put('/company', data, config)
    return request.then(payload => {
      dispatch({
        type    : 'CHANGE_COMPANY_EMAIL',
        payload : email,
      })
      return payload
    }).catch(err => {
      return err
    })
  }

  /*postActivity (period_start_date, period_end_date, config) {
    const data = {
      period_start_date,
      period_end_date,
    }
    return this.api.post('/company/activity', data, config)
  }*/
}

export default Company
