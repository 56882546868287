function parseResponse (response) {
  if (response) {
    if (response.status === 200) {
      return response.data
    }
    if (response.data) {
      return new Error(response.data.message || 'UNKNOW_ERROR' )
    }
    throw new Error( response.problem || 'UNKNOW_ERROR' )
  }
  throw new Error( 'NO_RESPONSE' )
}

function normalizeException (error) {
  console.log('error', error)
  console.log('error.response', error.response)
  if (error.response) {
    throw new Error(error.response.data.message || 'STATUS_ERROR')
  } else if (error.request) {
    throw new Error('NO_RESPONSE')
  } else {
    throw new Error('UNKNOW_ERROR')
  }
}

export {
  parseResponse,
  normalizeException,
}
