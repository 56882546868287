import {dispatch} from 'recost'

class User {
  constructor (api) {
    this.api = api
  }

  login(companyRef, password, config) {
    const data = {
      companyRef,
      password,
    }
    return this.api.post('/login', data, config).then(
      (payload) => {
        dispatch({ 
          type : 'FETCH_USER_FULFILLED', 
          payload,
        })
        return payload
      }
    ).catch(
      (err) => {
        dispatch({ 
          type    : 'FETCH_USER_REJECTED', 
          payload : err,
        })
        return err
      }
    )
  }

  logout(config) {
    return this.api.post('/logout', {}, config).then(
      () => dispatch({
        type : 'LOGOUT',
      })
    ).catch(
      () => dispatch({
        type: 'LOGOUT_REJECTED',
      })
    )
  }

  getUser ( config={} ) {
    dispatch({
      type : 'FETCH_USER_PENDING',
    })
    return this.api.get('/users/me', config).then(payload => {
      dispatch({ 
        type : 'FETCH_USER_FULFILLED', 
        payload,
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type    : 'FETCH_USER_REJECTED', 
        payload : err,
      })
      return err
    })
  }

  changeEmail(email, config) {
    dispatch({
      type : 'CHANGE_EMAIL_PENDING',
    })
    
    return this.api.put('/users/me', {email}, config).then(user => {
      dispatch({
        type    : 'CHANGE_EMAIL_FULFILLED', 
        payload : user.email,
      })
      return user.email
    }).catch(err => {
      dispatch({ 
        type    : 'CHANGE_EMAIL_REJECTED', 
        payload : err,
      })
      return err
    })
  }

  requestForgotPassword(companyRef, config) {
    dispatch({
      type : 'REQUEST_FORGOTPASSWORD_PENDING',
    })
    const request = this.api.post('/users/pwreset', {companyRef}, config);
    return request.then(() => {
      dispatch({
        type : 'REQUEST_FORGOTPASSWORD_FULFILLED',
      })
      return true
    }).catch(() => {
      dispatch({
        type : 'REQUEST_FORGOTPASSWORD_REJECTED',  
      })
      return false
    })
  }

  emailConfirm(id, token, config) {
    dispatch({
      type: 'CONFIRM_EMAIL_PENDING',
    })
    const request = this.api.get(`/users/${id}/emailconfirm/${token}`, config)
    return request.then(() => {
      dispatch({
        type: 'CONFIRM_EMAIL_FULFILLED',
      })
      return true
    }).catch(() => {
      dispatch({
        type: 'CONFIRM_EMAIL_REJECTED',
      })
      return false
    })
  }

  verifyForgotPassword(id, token, config) {
    dispatch({
      type : 'VERIFY_FORGOTPASSWORD_PENDING',
    })
    const request = this.api.get(`/users/${id}/pwreset/${token}`, config);
    return request.then(() => {
      dispatch({
        type : 'VERIFY_FORGOTPASSWORD_FULFILLED',
      })
      return true
    }).catch(() => {
      dispatch({
        type : 'VERIFY_FORGOTPASSWORD_REJECTED',  
      })
      return false
    })
  }
  useForgotPassword(id, token, password, config) {
    dispatch({
      type : 'USE_FORGOTPASSWORD_PENDING',
    })
    const request = this.api.put(
      `/users/${id}/pwreset/${token}`, 
      { password }, 
      config,
    )
    return request.then(() => {
      dispatch({
        type : 'USE_FORGOTPASSWORD_FULFILLED',
      })
      return true
    }).catch(() => {
      dispatch({
        type : 'USE_FORGOTPASSWORD_REJECTED',  
      })
      return false
    })
  }

  changePassword(password, config) {
    dispatch({
      type : 'CHANGE_PASSWORD_PENDING',
    })
    
    this.api.put('/users/me', {password}, config).then(payload => {
      dispatch({ 
        type : 'CHANGE_PASSWORD_FULFILLED', 
        payload,
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type    : 'CHANGE_PASSWORD_REJECTED', 
        payload : err,
      })
      return err
    })
  }

}

export default User
