import {dispatch} from 'recost'

class Qualifications {
  constructor (api) {
    this.api = api
  }

  getQualifications (activityId, config) {
    dispatch({
      type: 'FETCH_QUALIFICATIONS_PENDING',
    })
    return this.api.get(`/employees/qualifications?activityId=${activityId}`, config).then(
      ({qualifications}) => dispatch({
        type    : 'FETCH_QUALIFICATIONS_FULFILLED', 
        payload : qualifications.map(
          type => ({
            ...type,
            value : type.id,
          })
        ),
      })
    ).catch(err => dispatch({
        type    : 'FETCH_QUALIFICATIONS_REJECTED', 
        payload : err,
      }))
  }
}

export default Qualifications
