export default function reducer(state, action) {
  switch (action.type) {
    
    // --- FETCH_MESSAGES ------------------------------------------------------
    case 'FETCH_MESSAGES_PENDING':
      return {
        ...state,
        loadingMessages: true,
        messagesError: false,
      }
    case 'FETCH_MESSAGES_FULFILLED':
      return {
        ...state,
        messages: action.payload,
        loadingMessages: false,
        messagesError: false,
      }
    case 'FETCH_DOCUMENTS_REJECTED':
      return {
        ...state,
        messagesError: true,
        loadingMessages: false,
        messages: [],
      }
    default:
      return state
  }
}
