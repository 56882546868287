export default function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_STATES_PENDING':
      return {
        ...state,
        loadingStates : true,
        statesFetched : false,
        error         : false,
      }
    case 'FETCH_STATES_FULFILLED':
      return {
        ...state,
        loadingStates : false,
        statesFetched : true,
        error         : false,
        states        : action.payload,
      }
    case 'FETCH_STATES_REJECTED':
      return {
        ...state,
        loadingStates : false,
        statesFetched : false,
        error         : true,
      }
    default:
      return state
  }
}
