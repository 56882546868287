export default function reducer(state, action) {
  switch (action.type) {
  case 'UPDATE_SALARY':
    const stateSalaries = [...state.salaries]
    const salary = stateSalaries.find(salary => salary.id === action.payload.id)
    const fieldsToUpdate = Object.keys(action.payload)
    console.log(action.payload)
    for (const field of fieldsToUpdate) {
      if (field === 'portal_comment'){
        salary[field] = action.payload[field]
      }else{
        const value = parseFloat(action.payload[field])
        salary[field] = (isNaN(value) || value =='0') ? action.payload[field] : value}
    }
    return {
      ...state,
      salaries: stateSalaries,
    }
  case 'UPDATE_SALARIES_PENDING':
    return {
      ...state,
      updatingSalaries: true,
    }
  
  case 'REMOVE_SALARY_PENDING':
    return {
      ...state,
      loadingSalaries : {
        type    : 'REMOVE',
        payload : action.payload,
      },
    }
  case 'REMOVE_SALARY_FULFILLED':
    const newSalaries = state.salaries.filter( (salary) => {
      return salary.id !== action.payload
    })
    return {
      ...state,
      loadingSalaries : false,
      salaries: newSalaries,
    }
  case 'REMOVE_SALARY_REJECTED':
    return {
      ...state,
      error           : {
        type    : 'REMOVE',
        payload : action.payload,
      },
      loadingSalaries : false,
    }

  // --- ADD_EMPLOYEE ----------------------------------------------------------
  case 'ADD_SALARY_PENDING':
    return {
      ...state,
      loadingSalaries : {
        type: 'ADD',
      },
    }
  case 'ADD_SALARY_FULFILLED':
    return {
      salaries        : [
        ...(state.salaries || []),
        action.payload,
      ],
      loadingSalaries : false,
    }
  case 'ADD_SALARY_REJECTED':
    return {
      ...state,
      error           : {
        type : 'ADD',
      },
      loadingSalaries : false,
    }
    
  case 'UPDATE_SALARIES_FULFILLED':
    const updatedSalaries = [...state.salaries]
    return {
      ...state,
      updatingSalaries: false,
      salaries: updatedSalaries.map(salary => ({ ...salary, updated: false })),
    }
  case 'UPLOAD_FILE_FULFILLED':
    const salariesToUpdate = [...state.salaries]
    const salaryToUpdate = salariesToUpdate.find(s => s.id === action.payload.id)
    if (salaryToUpdate) {
      salaryToUpdate[action.payload.field] = true
    }
    return {
      ...state,
      salaries: salariesToUpdate,
    }

  case 'FETCH_SALARIES_PENDING':
    return {
      ...state,
      loadingSalaries: true,
      salaries: [],
      salariesTotal: 0,
      salariesActivityId: action.payload && action.payload.activityId,
    }
  case 'FETCH_SALARIES_FULFILLED':
    const { offset, limit, salaries, total, startDate, endDate } = action.payload
    return {
      ...state,
      loadingSalaries: false,
      error: false,
      salaries,
      salariesTotal: total,
      startDate,
      endDate,
      salariesOffset: offset,
      salariesLimit: limit,
    }
  case 'FETCH_SALARIES_REJECTED':
    return {
      ...state,
      loadingSalaries: false,
      error: true,
      salaries: [],
      salariesTotal: 0,
    }
  default:
    return state
  }
}
