export default function reducer(state, action) {
  switch (action.type) {
    
    // --- FETCH_DOCUMENTS -----------------------------------------------------
    case 'FETCH_DOCUMENTS_PENDING':
      let newDocuments = [...state.documents]
      if (action.payload.activityId !== state.documentsActivityId) {
        newDocuments = []
      }
      return {
        ...state,
        loadingDocuments: {
          type: 'FETCH',
        },
        documents: newDocuments,
        documentsActivityId: action.payload.activityId,
        documentsFetched: false,
        error: false,
      }
    case 'FETCH_DOCUMENTS_FULFILLED':
      return {
        ...state,
        documents: [
          ...action.payload,
        ],
        loadingDocuments : false,
        documentsFetched : true,
        error            : false,
      }
    case 'FETCH_DOCUMENTS_REJECTED':
      return {
        ...state,
        error: {
          type  : 'FETCH',
        },
        documentsActivityId: undefined,
        documentsFetched: false,
        loadingDocuments: false,
      }
    
    
    // --- REMOVE_DOCUMENT -----------------------------------------------------
    case 'REMOVE_DOCUMENT_PENDING':
      return {
        ...state,
        loadingDocuments : {
          type    : 'REMOVE',
          payload : action.payload,
        },
      }
    case 'REMOVE_DOCUMENT_FULFILLED':
      const documents = state.documents.filter( (doc) => {
        return doc.id !== action.payload
      })
      return {
        ...state,
        loadingDocuments : false,
        documents,
      }
    case 'REMOVE_DOCUMENT_REJECTED':
      return {
        ...state,
        error     : {
          type    : 'REMOVE',
          payload : action.payload,
        },
        loadingDocuments   : false,
      }

    
    // --- UPLOAD_DOCUMENT -----------------------------------------------------
    case 'ADD_DOCUMENT_PENDING':
      return {
        ...state,
        loadingDocuments : {
          type: 'UPLOAD',
        },
      }
    case 'UPLOAD_DOCUMENT_FULFILLED':
      return {
        documents : [
          ...state.documents,
          action.payload,
        ],
      loadingDocuments : false,
      }
    case 'UPLOAD_DOCUMENT_REJECTED':
      return {
        ...state,
        error   : {
          type  : 'UPLOAD',
        },
        loadingDocuments : false,
      }
    
    default:
      return state
  }
}
