import React, { PureComponent } from 'react'
import { withState } from 'recost'

import style from './style.module.scss'
import cpmbgLogo from 'assets/logo.png'
import Link from './Link'

import { api } from 'services'

class Header extends PureComponent {

  componentDidMount = () => {
    const { company, loading } = this.props
    if (!company || !company.name || !loading) {
      api.company.getCompany()
    }
  }

  render() {
    const { company, auth } = this.props
    const { name, partner_ref, origin = {} } = company
    return <>

      <div className={style.header}>
        <div className={style.headerLogo}>
          <Link to="/">
            <img alt="CPMBG" src={cpmbgLogo} />
          </Link>
        </div>
        {auth && (
          <>
            <div className={style.menuStart}>
              <Link to="/" name="Accueil" />
              <Link to="/activities/current" name="Déclaration(s) en cours" />
              <Link to="/activities/posted" name="Historiques des déclarations" />
              <Link to="/profile" name="Mes données" />
              <Link to="/contact" name="Contact" />
              <Link to="/wiki" name="Aide" />
            </div>
            <div className={style.menuEnd}>
              {name}<br />
              {partner_ref}<br />
              {origin.name}<br />
              <Link to="/logout" name="Logout" />
            </div>
          </>
        )}
      </div>
    
      <div className={style.headerSpacer}></div>

    </>
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  loading: state.loadingCompany,
})
export default withState(mapStateToProps)(
  Header
)
