export default function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_QUALIFICATIONS_PENDING':
      return {
        ...state,
        loadingQualifications : true,
        qualificationsFetched : false,
        error                 : false,
      }
    case 'FETCH_QUALIFICATIONS_FULFILLED':
      return {
        ...state,
        loadingQualifications : false,
        qualificationsFetched : true,
        error                 : false,
        qualifications        : action.payload,
      }
    case 'FETCH_QUALIFICATIONS_REJECTED':
      return {
        ...state,
        loadingQualifications : false,
        qualificationsFetched : false,
        error                 : true,
      }
    default:
      return state
  }
}
