export default function reducer(state, action) {
  switch (action.type) {

    // --- CHANGE USERNAME / PASSWORD ------------------------------------------
    case 'CHANGE_EMAIL_PENDING':
      return {
        ...state,
        errorEmail      : false,
        emailChanged    : false,
      }
    case 'CHANGE_EMAIL_FULFILLED':
      return {
        ...state,
        email           : action.payload,
        errorEmail      : false,
        emailChanged    : true,
      }
    case 'CHANGE_EMAIL_REJECTED':
      return {
        ...state,
        errorEmail : action.payload,
      }
    case 'CHANGE_PASSWORD_PENDING':
      return {
        ...state,
        errorPassword   : false,
        passwordChanged : false,
      }
    case 'CHANGE_PASSWORD_FULFILLED':
      return {
        ...state,
        errorPassword   : false,
        passwordChanged : true,
      }
    case 'CHANGE_PASSWORD_REJECTED':
      return {
        ...state,
        errorPassword : action.payload,
      }
    

    // --- FETCH ---------------------------------------------------------------
    case 'FETCH_USER_FULFILLED':
      return {
        ...state,
        id    : action.payload.id,
        email : action.payload.email,
        auth  : true,
      }
    case 'FETCH_USER_REJECTED':
      return {
        ...state,
        error : true,
        auth  : false,
      }
    case 'LOGOUT':
      return {
        ...state,
        auth  : false,
        id    : null,
        email : null,
      }


    // --- FORGOT PASSWORD -----------------------------------------------------
    case 'REQUEST_FORGOTPASSWORD_PENDING':
      return {
        ...state,
        forgotPassword : {
          requesting : true,
        },
      }
    case 'REQUEST_FORGOTPASSWORD_FULFILLED':
      return {
        ...state,
        forgotPassword : {
          waitingForToken : true,
        },
      }
    case 'REQUEST_FORGOTPASSWORD_REJECTED':
      return {
        ...state,
        forgotPassword : {
          error   : true,
        },
      }
    case 'VERIFY_FORGOTPASSWORD_PENDING':
      return {
        ...state,
        forgotPassword : {
          verifying : true,
        },
      }
    case 'VERIFY_FORGOTPASSWORD_FULFILLED':
      return {
        ...state,
        forgotPassword : {
          complete : true,
        },
      }
    case 'VERIFY_FORGOTPASSWORD_REJECTED':
      return {
        ...state,
        forgotPassword : {
          invalidToken : true,
        },
      }
    default:
      return state
  }
}
