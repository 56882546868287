import {dispatch} from 'recost'

import { editableFields } from 'utils/salary'
import { paginationLimit } from 'config/constants';

class Salary {
  constructor (api) {
    this.api = api
  }

  updateSalaries (activityId, salaries, config) {
    dispatch({ type: 'UPDATE_SALARIES_PENDING' })
    const data = {
      salaries: salaries.map((salary) => 
        editableFields.reduce(
          (obj = {}, field) => ({ 
          ...obj, [field]: salary[field],
          }),
          { id: salary.id }
        )
      ),
    }
    return this.api.put(`/activities/${activityId}/salaries`, data).then((result) => {
      dispatch({ type: 'UPDATE_SALARIES_FULFILLED' })
      return result
    }).catch((error) => {
      dispatch({ type: 'UPDATE_SALARIES_REJECTED' })
      return error
    })
  }

  getSalariesByActivity (activityId, offset=0, limit=paginationLimit, config = {}) {
    dispatch({ type: 'FETCH_SALARIES_PENDING', payload: { activityId } })
    config.params = {
      ...config.params,
      offset,
      limit,
    }
    return this.api.get(`/activities/${activityId}/salaries`, config).then(
      (payload) => {
        payload = {
          offset,
          limit,
          ...payload,
          activityId,
        }
        dispatch({ type: 'FETCH_SALARIES_FULFILLED', payload })
        return payload
      }
    ).catch((error) => {
      dispatch({ type: 'FETCH_SALARIES_REJECTED', payload: error })
      return error
    })
  }
}

export default Salary
