import {dispatch} from 'recost'

class Salary13Types {
  constructor (api) {
    this.api = api
  }

  getTypes (config) {
    dispatch({
      type: 'FETCH_SALARY13TYPES_PENDING',
    })
    return this.api.get('/salaries/getsalary13', config).then(
      ({types}) => dispatch({
        type    : 'FETCH_SALARY13TYPES_FULFILLED',
        payload : types.map(
          type => ({
            ...type,
            value : type.id,
          })
        ),
      })
    ).catch(err => dispatch({
        type    : 'FETCH_SALARY13TYPES_REJECTED',
        payload : err,
      }))
  }
}

export default Salary13Types
