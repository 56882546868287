import { dispatch } from 'recost'

export default class Message {
  constructor(api) {
    this.api = api
  }

  getPreferences(config) {
    return this.api.get('/messages/preferences', config).then((preferences) => {
      return preferences.map((pref) => ({
        label: pref.name,
        value: pref.id,
      }))
    })
  }

  getVariant (config) {
    return this.api.get('/messages/variant', config).then(
      variant => variant ? variant.id : false
    )
  }

  setVariant (variant, config) {
    return this.api.put('/messages/variant', {variant}, config)
  }

  sendMessage (message, config) {
    dispatch({
      type: 'POST_MESSAGE_PENDING',
    })
    const data = {
      message,
    }
    const request = this.api.post('/messages', data, config)
    return request.then(payload => {
      dispatch({
        type: 'POST_MESSAGE_FULFILLED',
      })
      return payload
    }).catch(err => {
      dispatch({
        type    : 'POST_MESSAGE_REJECTED',
        payload : err,
      })
      throw err
    })
  }


  getMessages () {
    
    dispatch({ type : 'FETCH_MESSAGES_PENDING' })

    this.api.get('/messages').then((messages) => {
      dispatch({
        type: 'FETCH_MESSAGES_FULFILLED',
        payload: messages,
      })
      return messages
    }).catch((error) => {
      dispatch({
        type: 'FETCH_MESSAGES_REJECTED',
        payload: error,
      })
    })
  }
}
