import React, { lazy, Suspense } from 'react'
import PrivateRoute from 'components/utils/PrivateRoute'
import { Switch, Route } from 'react-router'
import style from './style.module.scss'

import Footer from 'components/layout/Footer'
import Loading from 'views/Loading'

const Profile        = lazy( () => import('views/Profile')              )
const Wiki           = lazy( () => import('views/Wiki')                 )
const Contact        = lazy( () => import('views/Contact')              )
const Activities     = lazy( () => import('views/Activities')           )
const Declaration    = lazy( () => import('views/Declaration')          )
const Affiliation    = lazy( () => import('views/Affiliation')          )
const Variants       = lazy( () => import('views/Variants')             )
const AddEmployee    = lazy( () => import('views/AddEmployee')          )
const Documents      = lazy( () => import('views/Documents')            )
const Confirmation   = lazy( () => import('views/Confirmation')         )
const UploadDocument = lazy( () => import('views/UploadDocument')       )
const Dashboard      = lazy( () => import('views/Dashboard')            )
const CompanyInfo    = lazy( () => import('views/CompanyInfo')          )
const Login          = lazy( () => import('views/Login/Login')          )
const Logout         = lazy( () => import('views/Login/Logout')         )
const ForgotPassword = lazy( () => import('views/Login/ForgotPassword') )
const VerifyToken    = lazy( () => import('views/Login/VerifyToken')    )
const EmailConfirm   = lazy( () => import('views/Account/EmailConfirm') )
const Account        = lazy( () => import('views/Account')              )
const NoMatch        = lazy( () => import('views/404')                  )

export default function () {

  return <div className={style.mainContainer}>
    <div className={style.bodyContainer}>

    <Suspense fallback={ <Loading /> }>
      <Switch>


        <Route path="/logout" render={() => <Logout />} />
        <Route path="/resetpassword/:id/:token" render={() => <VerifyToken />} />
        <Route path="/emailconfirm/:id/:token" render={() => <EmailConfirm />} />
        <Route path="/login/forgot" render={() => <ForgotPassword />} />
        <Route path="/login" render={() => <Login />} />

        <PrivateRoute exact path="/" render={() => <Dashboard />} />
        <PrivateRoute exact path="/account" render={() => <Account />} />
        <PrivateRoute exact path="/info" render={() => <CompanyInfo />} />


        <PrivateRoute path="/activities/:state" render={({match}) => 
          <Activities state={match.params.state} />
        } />

        <PrivateRoute path="/contact" render={() => <Contact />} /> 

        <PrivateRoute path="/wiki" render={() => <Wiki />} /> 

        <PrivateRoute path="/profile" render={() => <Profile />} /> 

        <PrivateRoute exact path="/declaration/:id/salaries" render={() => <Declaration />} />
        <PrivateRoute exact path="/affiliation/:id/employees" render={() => <Affiliation />} />


        <PrivateRoute exact path="/affiliation/:id/add" render={
          ({match}) => <AddEmployee affiliation activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/declaration/:id/add" render={
          ({match}) => <AddEmployee declaration activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/affiliation/:id/edit/:salaryId" render={
          ({match}) => <AddEmployee salaryId={match.params.salaryId} affiliation activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/declaration/:id/edit/:salaryId" render={
          ({match}) => <AddEmployee salaryId={match.params.salaryId} declaration activityId={match.params.id} />
        } />


        <PrivateRoute exact path="/affiliation/:id/variants" render={
          ({match}) => <Variants activityId={match.params.id} />
        } />


        <PrivateRoute exact path="/affiliation/:id/documents" render={
          ({match}) => <Documents affiliation activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/declaration/:id/documents" render={
          ({match}) => <Documents declaration activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/affiliation/:id/documents/upload" render={
          ({match}) => <UploadDocument affiliation activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/declaration/:id/documents/upload" render={
          ({match}) => <UploadDocument declaration activityId={match.params.id} />
        } />


        <PrivateRoute exact path="/declaration/:id/confirmation" render={
          ({match}) => <Confirmation declaration activityId={match.params.id} />
        } />
        <PrivateRoute exact path="/affiliation/:id/confirmation" render={
          ({match}) => <Confirmation affiliation activityId={match.params.id} />
        } />

        <Route render={() => <NoMatch />} />
      </Switch>
    </Suspense>
    
    </div>
    <div className={style.footerContainer}>
      <Footer />
    </div>
  </div>
}
