import React, { memo } from 'react'

import style from './style.module.scss'

function Footer() {
  return <footer className={style.footer}>
    <div className="content container">
      <p className="has-text-weight-semibold">
        Conférence paritaire de la métallurgie du bâtiment et Service EXECO
      </p>
      <ul>
        <li>Case postale 455</li>
        <li>1211 Genève 12</li>
        <li>T +41(0)22 839 73 30 du lundi au vendredi de 8h00 à 12h00</li>
        <li>F +41(0)22 839 73 39</li>
        <li><a href="mailto:info@execogeneve.ch">info@execogeneve.ch</a></li>
      </ul>
    </div>
  </footer>
}

export default memo(Footer)
