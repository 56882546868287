import React, { memo } from 'react'
import { translate } from 'react-multi-lang'

import Header from 'components/layout/Header'
import Message from 'components/elements/Message'

function Loading({ t }) {
  return <>

    <Header />

    <div style={{marginTop: '5rem'}}></div>

    <Message loading text={ t('LOADING') } />

  </>
}

export default memo(
  translate(Loading)
)
