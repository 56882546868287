export default function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_SALARY13TYPES_PENDING':
      return {
        ...state,
        loadingSalary13Types : true,
        salaryTypesFetched : false,
        error              : false,
      }
    case 'FETCH_SALARY13TYPES_FULFILLED':
      return {
        ...state,
        loadingSalary13Types : false,
        salaryTypesFetched : true,
        error              : false,
        salaryTypes        : action.payload,
      }
    case 'FETCH_SALARY13TYPES_REJECTED':
      return {
        ...state,
        loadingSalary13Types : false,
        salaryTypesFetched : false,
        error              : true,
      }
    default:
      return state
  }
}
