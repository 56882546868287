import api from './api'

class Auth {
  constructor() {
    this.getAuthenticatedUser()
  }

  async getAuthenticatedUser() {
    if (!this.isAuthenticated) {
      const user = await api.user.getUser()
      if (user.email) {
        this.isAuthenticated = true
        this.companyRef = user.companyRef
        this.id = user.id
      } else {
        this.isAuthenticated = false
      }
    }
    return {
      companyRef      : this.companyRef,
      id              : this.id,
      isAuthenticated : this.isAuthenticated,
    }
  }

  async authenticate(companyRef, password) {
    return api.user.login(companyRef, password).then(
      (user) => {
        if( user && user.email ) {
          this.companyRef = user.companyRef
          this.isAuthenticated = true
        } else {
          this.signout()
        }
        return this.isAuthenticated
      }
    ).catch(
      (err) => {
        this.signout()
        return this.isAuthenticated
      }
    )
  }

  async signout() {
    return api.user.logout().then(
      () => {
        this.isAuthenticated = false
        this.companyRef = null
      }
    ).catch(
      () => window.location.reload()
    )
  }
}

const auth = new Auth()

export default auth
