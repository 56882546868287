
export const editableFields = [
  'end_date',
  'duration_in_days',
  'avs_gross_salary',
  'salary_ldet_h0718',
  'salary_ldet_h1820',
  'salary_ldet_h2006',
  'comment',
  'portal_comment',
]

export const uploadFields = [
  'portal_uploaded_salary_document',
  'portal_uploaded_work_duration_document',
  'portal_uploaded_attestation_document',
]

export const compareSalaryValues = (salary1, salary2) => {
  return editableFields.reduce(
    (result, field) => salary1[field] === salary2[field] && result,
    true
  )
}
