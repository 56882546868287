import {dispatch} from 'recost'

class Documents {
  constructor (api) {
    this.api = api
  }

  getDocuments (activityId, config={} ) {
    dispatch({
      type : 'FETCH_DOCUMENTS_PENDING',
      payload: { activityId },
    })
    let query = ''
    if (activityId) {
      query = `?activityId=${activityId}`
    }
    const request = this.api.get(`/documents${query}`, config);
    return request.then(({documents}) => {
      dispatch({ 
        type : 'FETCH_DOCUMENTS_FULFILLED', 
        payload: documents,
      })
      return documents
    }).catch(err => {
      dispatch({ 
        type    : 'FETCH_DOCUMENTS_REJECTED', 
        payload : err,
      })
      return err
    })
  }

  postDocuments(docs, config) {
    dispatch({
      type : 'POST_DOCUMENT_PENDING',
    })
    //const request = this.api.post('/documents/', docs, config);
    const request = new Promise( (resolve, reject) => {
      setTimeout( () => {
        resolve()
        //reject()
      }, 500)
    })
    return request.then(payload => {
      dispatch({ 
        type : 'POST_DOCUMENT_FULFILLED', 
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type : 'POST_DOCUMENT_REJECTED', 
      })
      return err
    })
  }

  uploadDocument(file, name, activityId, config={}) {
    dispatch({
      type : 'UPLOAD_DOCUMENT_PENDING',
    })

    config = {
      headers : {
        'content-type': 'multipart/form-data',
      },
      ...config,
    }
    
    const data = new FormData()
    data.append('file', file, file.name)
    data.append('name', name)
    data.append('activityId', activityId)
    data.append('date', new Date())
    const request = this.api.post('/documents', data, config)

    return request.then(payload => {
      dispatch({ 
        type : 'UPLOAD_DOCUMENT_FULFILLED', 
        payload,
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type    : 'UPLOAD_DOCUMENT_REJECTED', 
        payload : err,
      })
      return err
    })
  }

  removeDocument(id, config) {
    dispatch({
      type : 'REMOVE_DOCUMENT_PENDING',
    })
    const request = this.api.delete('/documents/' + id, config);
    return request.then(payload => {
      dispatch({ 
        type : 'REMOVE_DOCUMENT_FULFILLED', 
        payload: id,
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type    : 'REMOVE_DOCUMENT_REJECTED', 
        payload : id,
      })
      return err
    })
  }
}

export default Documents
