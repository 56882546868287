import React, { PureComponent } from 'react'
import { Route, Redirect } from 'react-router'
import { auth } from 'services'

export default class PrivateRoute extends PureComponent {

  state = {
    loading : true,
  }

  componentDidMount = async () => {
    const user = await auth.getAuthenticatedUser()
    this.setState({
      isAuthenticated : user.isAuthenticated,
      loading         : false,
    })
  }

  renderContent = (props) => {

    const { isAuthenticated } = this.state

    const {
      component: Component,
      render,
    } = this.props

    return isAuthenticated ? (
        ( Component && <Component {...props} /> ) || 
        ( render && render(props) ) || 
        null
    ) : (
      <Redirect to={{
          pathname: '/login',
          search: `?redirect=${props.location.pathname}`,
        }}
      />
    )
  }

  render () {
    
    const { 
      component: Component, 
      render: privateRender, 
      ...rest
    } = this.props

    const { loading } = this.state
    
    return (
      !loading &&
        <Route {...rest} render={this.renderContent} />
    )
  }
}
