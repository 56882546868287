import { dispatch } from 'recost'
import { paginationLimit } from 'config/constants'

class Employees {
  constructor (api) {
    this.api = api
  }

  getProfessions (config) {
    return this.api.get('/employees/professions', config).then((professions) => {
      return professions.map((profession) => ({
        label: profession.name,
        id: profession.id,
      }))
    })
  }

  uploadFile (id, file, name, config) {
    const data = new FormData()
    data.append('id', id)
    data.append('name', name)
    data.append('file', file, file.name)
    return this.api.post(`/employees/${id}/file`, data, config)
  }

  getEmployee (id, config) {
    return this.api.get(`/employees/${id}`, config).then((data) => {
      return { 
        ...data,
        qualification_id: data.qualification && data.qualification.id,
      }
    })
  }

  getEmployees ( activityId, offset=0, limit=paginationLimit, config={} ) {
    dispatch({
      type : 'FETCH_EMPLOYEES_PENDING',
    })
    config.params = {
      ...config.params,
      offset,
      limit,
    }
    return this.api.get(`/activities/${activityId}/employees`, config).then(
      (payload) => {
        payload = {
          offset,
          limit,
          ...payload,
          activityId,
        }
        dispatch({ 
          type : 'FETCH_EMPLOYEES_FULFILLED', 
          payload,
        })
        return payload
      }
    ).catch(
      (error) => {
        dispatch({ 
          type    : 'FETCH_EMPLOYEES_REJECTED', 
          payload : error,
        })
        return error
      }
    )
  }

  editEmployee (id, salary, photo, declaration, config) {
    const target = declaration ? 'SALARY' : 'EMPLOYEE'
    let data = { ...salary }
    if(photo){
      data = new FormData()
      data.append('file', photo, photo.name)
      data.append('json', JSON.stringify(salary))
    }
    return this.api.put(`/employees/${id}`, data, config).then(
      (payload) => {
        dispatch({ 
          type : `EDIT_${target}_FULFILLED`, 
          payload,
        })
        return payload
      }
    )
  }

  addEmployee(activityId, salary, photo, declaration, duplicateFrom, config = {}) {
    const target = declaration ? 'SALARY' : 'EMPLOYEE'
    dispatch({
      type : `ADD_${target}_PENDING`,
    })
    let data = { ...salary }
    console.log(salary)
    if(photo){
      data = new FormData()
      data.append('file', photo, photo.name)
      data.append('json', JSON.stringify(salary))
    }
    if (duplicateFrom) {
      config.params = {
        duplicateFrom,
      }
    }
    return this.api.post(`/activities/${activityId}/employees`, data, config).then(
      (payload) => {
        dispatch({ 
          type : `ADD_${target}_FULFILLED`, 
          payload,
        })
        return payload
      }
    )
  }

  removeEmployee(id, target = 'declaration', config) {
    if (target === 'declaration') {
      target = 'SALARY'
    } else {
      target = 'EMPLOYEE'
    }
    dispatch({
      type : `REMOVE_${target}_PENDING`,
    })
    const request = this.api.delete(`/employees/${id}`, config);
    return request.then(payload => {
      dispatch({ 
        type    : `REMOVE_${target}_FULFILLED`,
        payload : id,
      })
      return payload
    }).catch(err => {
      dispatch({ 
        type    : `REMOVE_${target}_REJECTED`, 
        payload : id,
      })
      return err
    })
  }
}

export default Employees
