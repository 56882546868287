import {dispatch} from 'recost'

const EXAMPLE_STATES = [
  { name:'Aargau', code:'AG' },
  { name:'Appenzell Ausserrhoden', code:'AR' },
  { name:'Appenzell Innerrhoden', code:'AI' },
  { name:'Basel-Landschaft', code:'BL' },
  { name:'Basel-Stadt', code:'BS' },
  { name:'Bern', code:'BE' },
  { name:'Fribourg', code:'FR' },
  { name:'Geneva', code:'GE' },
  { name:'Glarus', code:'GL' },
  { name:'Grisons', code:'GR' },
  { name:'Jura', code:'JU' },
  { name:'Luzern', code:'LU' },
  { name:'Neuchâtel', code:'NE' },
  { name:'Nidwalden', code:'NW' },
  { name:'Obwalden', code:'OW' },
  { name:'Schaffhausen', code:'SH' },
  { name:'Schwyz', code:'SZ' },
  { name:'Solothurn', code:'SO' },
  { name:'St. Gallen', code:'SG' },
  { name:'Thurgau', code:'TG' },
  { name:'Ticino', code:'TI' },
  { name:'Uri', code:'UR' },
  { name:'Valais', code:'VS' },
  { name:'Vaud', code:'VD' },
  { name:'Zug', code:'ZG' },
  { name:'Zürich', code:'ZH' },
]

class States {
  constructor (api) {
    this.api = api
  }

  getStates (config) {
    dispatch({
      type: 'FETCH_STATES_PENDING',
    })
    //const request = this.api.get('/states', config);
    const request = new Promise((resolve,reject)=>{
      setTimeout(()=>{
        resolve(EXAMPLE_STATES)
        //reject()
      },500)
    })
    return request
      .then(payload => dispatch({
        type: 'FETCH_STATES_FULFILLED', 
        payload,
      }))
      .catch(err => dispatch({
        type    : 'FETCH_STATES_REJECTED', 
        payload : err,
      }))
  }
}

export default States
