import { utc as moment } from 'moment'

export default function reducer(state, action) {
  switch (action.type) {
    // --- FETCH_employees ------------------------------------------------------
    case 'FETCH_EMPLOYEES_PENDING':
      return {
        ...state,
        loadingEmployees : {
          type: 'FETCH',
        },
        employeesActivityId: undefined,
        error: false,
        employeesFetched: false,
      }
    case 'FETCH_EMPLOYEES_FULFILLED':

      const {
        total,
        offset,
        limit,
        activityId,
      } = action.payload

      return {
        ...state,
        employeesFetched: true,
        employeesActivityId: activityId,
        employees: action.payload.employees,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        employeesOffset: offset,
        employeesLimit: limit,
        employeesTotal: total,
        loadingEmployees: false,
        error: false,
      }
    case 'FETCH_EMPLOYEES_REJECTED':
      return {
        ...state,
        error           : {
          type  : 'FETCH',
        },
        employeesActivityId: undefined,
        employeesFetched: false,
        loadingEmployees: false,
      }
    
    
    // --- FILTER_employees -----------------------------------------------------
    case 'FILTER_EMPLOYEES':
      return {
        ...state,
        employeesFilters : {
          start_date : moment(action.payload.start_date),
          end_date   : moment(action.payload.end_date),
        },
      }

    
    // --- REMOVE_SALARY -------------------------------------------------------
    case 'REMOVE_EMPLOYEE_PENDING':
      return {
        ...state,
        loadingEmployees : {
          type    : 'REMOVE',
          payload : action.payload,
        },
      }
    case 'REMOVE_EMPLOYEE_FULFILLED':
      const employees = state.employees.filter( (salary) => {
        return salary.id !== action.payload
      })
      return {
        ...state,
        loadingEmployees : false,
        employees,
      }
    case 'REMOVE_EMPLOYEE_REJECTED':
      return {
        ...state,
        error           : {
          type    : 'REMOVE',
          payload : action.payload,
        },
        loadingEmployees : false,
      }

    case 'UPLOAD_FILE_FULFILLED':
    const employeesToUpdate = [...state.employees]
    const employeeToUpdate = employeesToUpdate.find(s => s.id === action.payload.id)
    if (employeeToUpdate) {
      employeeToUpdate[action.payload.field] = true
    }
    return {
      ...state,
      employees: employeesToUpdate,
    }
    
    // --- ADD_EMPLOYEE ----------------------------------------------------------
    case 'ADD_EMPLOYEE_PENDING':
      return {
        ...state,
        loadingEmployees : {
          type: 'ADD',
        },
      }
    case 'ADD_EMPLOYEE_FULFILLED':
      return {
        employees        : [
          ...state.employees,
          action.payload,
        ],
        loadingEmployees : false,
      }
    case 'ADD_EMPLOYEE_REJECTED':
      return {
        ...state,
        error           : {
          type : 'ADD',
        },
        loadingEmployees : false,
      }
    
    default:
      return state
  }
}
