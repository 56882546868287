import axios from 'axios'
import { parseResponse, normalizeException } from './helpers'
import { apiAddress } from 'config/constants'

// Models
import Company from './models/company'
import Employees from './models/employees'
import Documents from './models/documents'
import States from './models/states'
import SalaryTypes from './models/salaryTypes'
import Salary13Types from './models/salary13Types'
import User from './models/user'
import Qualifications from './models/qualifications'
import CompanyActivity from './models/companyActivity'
import Salary from './models/salary'
import Message from './models/message'

let service

class Api {
  constructor (config = {}) {
    config = {
        baseURL: '/api',
        headers: {
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        timeout: 60000,
        withCredentials: true,
        ...config,
    }
    service = this.service = axios.create(config)

    service.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // Do something with response error
      console.log(error)
      return Promise.reject(error)
    })

    this.company = new Company(this)
    this.employees = new Employees(this)
    this.documents = new Documents(this)
    this.states = new States(this)
    this.salaryTypes = new SalaryTypes(this)
    this.salary13Types = new Salary13Types(this)
    this.qualifications = new Qualifications(this)
    this.user = new User(this)
    this.companyActivity = new CompanyActivity(this)
    this.salary = new Salary(this)
    this.message = new Message(this)
  }

  get (endpoint, config) {
    return service.get(endpoint, config).then(
      parseResponse
    ).catch(
      normalizeException
    )
  }

  put (endpoint, data, config) {
    return service.put(endpoint, data, config).then(
      parseResponse
    ).catch(
      normalizeException
    )
  }

  // const PATCH = (endpoint, data) => api.head(endpoint, data).then((response) => parseResponse(response)).catch((e) => normalizeException(e))

  post (endpoint, data, config) {
    return service.post(endpoint, data, config).then(
      parseResponse
    ).catch(
      normalizeException
    )
  }

  delete (endpoint, config) {
    return service.delete(endpoint, config).then(
      parseResponse
    ).catch(
      normalizeException
    )
  }
}

const api = new Api({ baseURL: apiAddress })

export default api
