export default function reducer(state, action) {
  switch (action.type) {
   case 'FETCH_ACTIVITIES_PENDING':
      return {
        ...state,
        error: false,
        loadingActivities: true,
      }
    case 'FETCH_ACTIVITIES_FULFILLED':
      return {
        ...state,
        activities: action.payload,
        error: false,
        loadingActivities: false,
      }
    case 'FETCH_ACTIVITIES_REJECTED':
      return {
        ...state,
        error: true,
        loadingActivities: false,
      }
    
    default:
      return state
  }
}
