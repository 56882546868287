import {dispatch} from 'recost'

class SalaryTypes {
  constructor (api) {
    this.api = api
  }

  getTypes (config) {
    dispatch({
      type: 'FETCH_SALARYTYPES_PENDING',
    })
    return this.api.get('/salaries/types', config).then(
      ({types}) => dispatch({
        type    : 'FETCH_SALARYTYPES_FULFILLED', 
        payload : types.map(
          type => ({
            ...type,
            value : type.id,
          })
        ),
      })
    ).catch(err => dispatch({
        type    : 'FETCH_SALARYTYPES_REJECTED', 
        payload : err,
      }))
  }
}

export default SalaryTypes
