import React, { memo } from 'react'

import style from './style.module.scss'

function Message ({ error, success, loading, text, icon, children }) {
  return <div className={style.message}>

    { loading && 
      <i className="fas fa-3x fa-spinner fa-pulse"></i> }

    { error && 
      <i className="fas fa-3x fa-exclamation-circle"></i> }

    { success &&
      <i className="fas fa-3x fa-check"></i>
    }

    { icon && 
      <i className={ 'fas fa-3x fa-'+icon }></i> }

    <div style={{marginTop:25}}>
      {text}
    </div>

    {children &&
      <div style={{marginTop:25}}>
        {children}
      </div>
    }

  </div>
}

export default memo(Message)
