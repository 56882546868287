import { dispatch } from 'recost'

class CompanyActivity {
  constructor (api) {
    this.api = api
  }

  getActivities (state) {
    dispatch({ type: 'FETCH_ACTIVITIES_PENDING' })
    return this.api.get(`/activities?state=${state}`).then(
      (result = { activities: [] }) => {
        dispatch({ type: 'FETCH_ACTIVITIES_FULFILLED', payload: result.activities })
        return result.activities
      }
    ).catch((error) => {
      dispatch({ type: 'FETCH_ACTIVITIES_REJECTED' })
      return error
    })
  }

  submitActivity (activityId) {
    dispatch({ type: 'SUBMIT_ACTIVITY_PENDING' })
    const data = { portal_state: 'posted' }
    return this.api.put(`/activities/${activityId}`, data).then(() => {
      dispatch({ type: 'SUBMIT_ACTIVITY_FULFILLED' })
      return this.getActivities()
    }).catch((error) => {
      dispatch({ type: 'SUBMIT_ACTIVITY_REJECTED' })
      return error
    })
  }

}

export default CompanyActivity
